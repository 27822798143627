var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getListDevices,"data-test":"dataTable-field","items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberDevices,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.online",fn:function(ref){
var item = ref.item;
return [_c('TerminalDialog',{attrs:{"enable-connect-button":true,"uid":item.uid,"online":item.online,"data-test":"terminalDialog-component"}})]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.uid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.tags[0])?_c('div',{staticClass:"mt-1"},_vm._l((item.tags),function(tag,index){return _c('v-tooltip',{key:index,attrs:{"bottom":"","disabled":!_vm.showTag(tag)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1 mb-1",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.filterByTag(tag)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.displayOnlyTenCharacters(tag))+" ")])]}}],null,true)},[(_vm.showTag(tag))?_c('span',[_vm._v(" "+_vm._s(tag)+" ")]):_vm._e()])}),1):_vm._e()]}},{key:"item.info.pretty_name",fn:function(ref){
var item = ref.item;
return [_c('DeviceIcon',{attrs:{"icon-name":item.info.id,"data-test":"deviceIcon-component"}}),_vm._v(" "+_vm._s(item.info.pretty_name)+" ")]}},{key:"item.namespace",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"list-itens"},[_vm._v(" "+_vm._s(_vm.address(item))+" "),_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){_vm.copySSHID(_vm.address(item))}}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getListDevices.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.detailsDevice(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" info ")]),_c('v-list-item-title',[_vm._v(" Details ")])],1),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationFormUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationFormUpdate},on:{"click":function($event){_vm.showTagDialog(_vm.getListDevices.indexOf(item))}}},[_c('TagFormUpdate',{attrs:{"device-uid":item.uid,"tags-list":item.tags,"show":_vm.tagDialogShow[_vm.getListDevices.indexOf(item)],"data-test":"tagFormUpdate-component"},on:{"update:show":function($event){_vm.$set(_vm.tagDialogShow, _vm.getListDevices.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationRemove},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationRemove},on:{"click":function($event){_vm.showDeviceDelete(_vm.getListDevices.indexOf(item))}}},[_c('DeviceDelete',{attrs:{"uid":item.uid,"show":_vm.deviceDeleteShow[_vm.getListDevices.indexOf(item)],"data-test":"deviceDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.deviceDeleteShow, _vm.getListDevices.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }